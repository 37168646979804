<!-- 入库单记录-详情 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>供货单位：</span>
        <span>{{ topData.supplier }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>单据编号：</span>
        <span>{{ topData.receipt }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>收货仓库：</span>
        <span>{{ topData.warehouse }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>入库日期：</span>
        <span>{{ topData.data }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>入库人：</span>
        <span>{{ topData.acceptor }}</span>
      </FormItem>
      <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
    </Form>
    <!--    <Table :productList="listColumns" :productData="listData">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ quantity_total }}</span> 合计金额：<span class="color389">¥{{ amount_total }}</span>
      </div>
    </Table> -->

    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :productList="listColumns" :productData="listData" :option_page="option_page" highlight-row border :row-class-name="rowClassName"></Table>
      <div class="pages borderRow" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div class="summary" v-if="listData && listData.length > 0">
        总数量：<span class="color389 marginRight20">{{ quantity_total }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(amount_total, true) }}</span>
      </div>
      <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div> -->
    </div>
    <span class="pageBtn finger btnReset fr marginTop" @click="goBack">返回</span>
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsWarehouseEntryRecordConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      tabloading: false,
      setupStatus: false,
      titleList: [],
      option_page: '20',
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 136,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 136,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 75,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 160,
        },
        {
          title: '单价(元)',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.unit_price)])
          },
          width: 160,
        },
        {
          title: '金额(元)',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.amount)])
          },
          width: 160,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 200,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          width: 136,
        },
        {
          title: '失效日期',
          key: 'valid_period',
          align: 'center',
          width: 136,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 235,
        },
        {
          title: '注册证有效期',
          key: 'licence_valid_period',
          align: 'center',
          width: 200,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          width: 260,
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          width: 136,
        },
      ],
      // 头部数据
      topData: {
        supplier: '',
        receipt: '',
        warehouse: '',
        data: '',
        acceptor: '',
      },
      warehouse_receipt_id: 0, // 入库单id
      quantity_total: 0, // 总数
      amount_total: 0, // 总金额
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
    }
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach(item => {
        totle += item.money * item.num
      })
      return totle
    },
  },
  methods: {
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http.get(this.$apiConsign.systemOptionItem, { option_page: this.option_page }).then(res => {
        this.titleList = res.data.enable
        localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
        for (let i = 0; i < this.titleList.length; i++) {
          if (this.titleList[i].show) {
            this.titleList[i].key = this.titleList[i].data_col
            this.titleList[i].width = this.titleList[i].column_width
            this.titleList[i].align = 'center'
            this.titleList[i].resizable = true
          }
        }
        this.listColumns = []
        this.listColumns.unshift({
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        })
        this.listColumns = [...this.listColumns, ...this.titleList]
        this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
      })
      // .then(() => {
      //   this.getDetail()
      // })
    },
    // 反冲成功的行标红
    rowClassName() {
      if (this.$route.query.recoil_status == 1) return 'redRow'
      return ''
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getDetail()
    },
    getDetail() {
      this.tabloading = true
      // let warehouse_receipt_id = this.warehouse_receipt_id
      let obj = {
        warehouse_receipt_id: this.warehouse_receipt_id,
        receipt_page: 1,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$apiConsign.inputOrderRecodeDetail, obj, true).then(res => {
        this.tabloading = false
        if (res.status) {
          for (const item of res.data.return_dict.result) {
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
            // 判断是录入的是年月还是年月日
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
            item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
            if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.business_license_valid_period = '长期'
            } else {
              item.business_license_valid_period = ''
            }
            if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.record_valid_period = '长期'
            } else {
              item.record_valid_period = ''
            }
            item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
            item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
            item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
            item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
            item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
            item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
            item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD') : ''
            // 采购单号
            item.return_code = item.order_number ? item.order_number : ''
            // 采购状态
            item.return_status = item.purchase_order_state_str ? item.purchase_order_state_str : ''
            // 采购时间
            item.return_time = item.insert_time ? item.insert_time : ''
          }
          this.listData = res.data.return_dict.result
          this.total = res.data.total
          this.quantity_total = res.data.return_dict.quantity_total
          this.amount_total = res.data.return_dict.amount_total
          let creatTime = res.data.return_dict.create_time ? this.$moment.unix(res.data.return_dict.create_time).format('YYYY-MM-DD') : ''
          this.$set(this.topData, 'supplier', res.data.return_dict.supplier_name)
          this.$set(this.topData, 'receipt', res.data.return_dict.order_number)
          this.$set(this.topData, 'warehouse', res.data.return_dict.warehouse_name)
          this.$set(this.topData, 'data', creatTime)
          this.$set(this.topData, 'acceptor', res.data.return_dict.create_by)
        }
      })
    },
    // 返回
    goBack() {
      this.$router.go(-1)
      // this.$router.push('/warehouseEntryRecord')
    },
  },
  created() {
    this.queryOption()
    let warehouse_receipt_id = this.$route.query.warehouse_receipt_id
    this.warehouse_receipt_id = warehouse_receipt_id
    this.getDetail()
  },
}
</script>

<style scoped lang="less">
.borderRow {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
</style>
